<template>
  <div
    class="userservice"
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 搜索 -->
    <div class="search">
      <el-form
        label-width="85px"
        style="display: flex; flex-wrap: wrap; align-items: center"
      >
        <!-- <el-form-item label="服务时间">
          <el-col>
            <el-date-picker
              class="in1"
              v-model="value1"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 200px"
              @change="changeTime"
            >
            </el-date-picker
            >-
            <el-date-picker
              class="in2"
              v-model="value2"
              type="datetime"
              placeholder="选择日期时间"
              style="width: 200px"
              @change="changeTime1"
            >
            </el-date-picker>
          </el-col>
        </el-form-item> -->
        <el-form-item label="服务时间">
          <el-date-picker
            v-model="overallForm.time"
            type="datetimerange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
            :default-time="['00:00:00', '23:59:59']"
            :default-value="overallForm.time"
            :clearable="false"
            @focus="focus"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="通话时长" style="margin-left: 30px">
          <el-select v-model="value" placeholder="请输入">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-select v-model="valueTime" placeholder="请输入">
            <el-option
              v-for="item in optionsTime"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="关键字查询">
          <el-select
            v-model="valueGuan"
            placeholder="输入关键字"
            @change="bian"
            clearable
          >
            <el-option
              v-for="item in optionsGuan"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
          <el-input
            class="in1"
            v-model="inputOne"
            placeholder="请输入内容"
            style="width: 200px"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="客服类型" style="margin-left: 15px">
          <el-select v-model="valueKe" placeholder="输入客服类型" clearable>
            <el-option
              v-for="item in optionsKe"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="通话类型" style="margin-left: 20px">
          <el-select v-model="valueTong" placeholder="输入通话类型" clearable>
            <el-option
              v-for="item in optionsTong"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <!-- <el-form-item label="设备类型" style="margin-right: 20px">
          <el-select v-model="valueShe" placeholder="输入设备类型" clearable>
            <el-option
              v-for="item in optionsShe"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="是否删除">
          <el-select v-model="del" placeholder="请输入" clearable>
            <el-option
              v-for="item in optionsDel"
              :key="item.code"
              :label="item.name"
              :value="item.code"
            ></el-option>
          </el-select>
          <img
            src="../../assets/images/query.png"
            alt=""
            @click="searchNei"
            style="width: 49px; margin-left: 24px"
          />
        </el-form-item>
        <!-- <el-form-item label-width="40px">
          <img src="../../assets/images/query.png" alt="" @click="searchNei" style="width:4%;" />
        </el-form-item> -->
      </el-form>
    </div>
    <!-- 表格 -->
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="通话ID"></el-table-column>
        <el-table-column prop="serviceType" label="客服类型"></el-table-column>
        <el-table-column prop="callType" label="通话类型"></el-table-column>
        <el-table-column
          prop="workName"
          label="姓名"
          width="110"
        ></el-table-column>
        <el-table-column prop="workNumber" label="客服工号"></el-table-column>
        <el-table-column
          prop="name"
          label="用户姓名"
          width="110"
        ></el-table-column>
        <el-table-column
          prop="customerNumber"
          label="来电号码"
          width="120"
        ></el-table-column>
        <el-table-column
          prop="equipmentType"
          label="用户设备"
          width="110"
        ></el-table-column>
        <el-table-column
          prop="equipmentNo"
          label="IMEI"
          width="150"
        ></el-table-column>
        <el-table-column
          prop="startTime"
          label="开始时间"
          width="130"
        ></el-table-column>
        <el-table-column
          prop="bridgeDuration"
          label="通话时长"
        ></el-table-column>
        <el-table-column
          prop="endReason"
          label="挂机原因"
          width="130"
        ></el-table-column>
        <el-table-column
          prop="delFlag"
          label="是否删除"
          width="130"
        ></el-table-column>
        <el-table-column prop="qiniuUrl" label="录音回放" width="200">
          <template slot-scope="scope">
            <el-button
              size="mini"
              type="danger"
              @click="handleSearch(scope.$index, scope.row)"
              >播放</el-button
            >
            <el-button
              size="mini"
              type="info"
              @click="downloadBtn(scope.$index, scope.row)"
              >下载</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 总结 -->
    <div class="total">
      <div class="left">
        <p>
          通话次数
          <span class="spanto">{{ totalCount }}</span
          >次
        </p>
        <p>
          通话时长
          <span class="spanto">{{ toTime }}</span
          >分钟
        </p>
      </div>
      <!-- 分页 -->
      <div class="pages">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :page-size="pageSize"
          :current-page="pageCount"
          layout="total, prev, pager, next"
          :total="totalCount"
        >
        </el-pagination>
      </div>
      <!-- <div class="right">
        <el-pagination
          :page-size="pagesize"
          :pager-count="pagercount"
          layout="slot, pager"
          :total="100"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
          <img class="lefts" src="../../assets/images/leftpage.png" alt />
          <img class="rights" src="../../assets/images/rightpage.png" alt />
        </el-pagination>
      </div> -->
    </div>
    <!-- 按钮 -->
    <div class="buttons">
      <el-button>数据分析</el-button>
      <el-button @click="exportHis">数据导出</el-button>
    </div>
    <!-- 录音的弹框 -->
    <el-dialog
      title="提示"
      :visible.sync="dialogVisibleYin"
      :before-close="handleDialogClose"
      width="30%"
    >
      <span v-if="isShow">无通话录音</span>
      <audio
        ref="audioLing"
        name="audioOne"
        controls
        controlslist="nodownload"
        :src="yinImage"
        @input="$forceUpdate()"
        @change="$forceUpdate()"
        type="audio/mp3"
        v-if="isShow1"
      ></audio>
    </el-dialog>
  </div>
</template>

<script>
import { apiShout } from "../../api1/configShout";
import { api } from "../../api1/config";
export default {
  data() {
    return {
      del: "",
      optionsDel: [],
      overallForm: {
        time: [],
      },
      clientHeight: document.body.clientHeight,
      inputOne: "",
      value1: "",
      value2: "",
      value: "",
      toTime: "",
      isShow: false,
      isShow1: true,
      dialogVisibleYin: false,
      yinImage: "",
      options: [
        {
          value: "0",
          label: "不限",
        },
        {
          value: "1",
          label: ">=",
        },
        {
          value: "2",
          label: "<=",
        },
      ],
      valueTime: "",
      optionsTime: [
        {
          value: "0",
          label: "0秒",
        },
        {
          value: "1",
          label: "15秒",
        },
        {
          value: "2",
          label: "5分钟",
        },
        {
          value: "3",
          label: "10分钟",
        },
      ],
      valueTong: "",
      optionsTong: [
        {
          value: "1",
          label: "呼入",
        },
        {
          value: "4",
          label: "呼出",
        },
      ],
      valueShe: "",
      optionsShe: [
        {
          value: "4",
          label: "智能机器人",
        },
        {
          value: "5",
          label: "智能呼叫器",
        },
      ],
      valueKe: "",
      optionsKe: [],
      valueGuan: "",
      optionsGuan: [
        {
          value: "0",
          label: "通话ID",
        },
        // {
        //   value: "1",
        //   label: "通话类型",
        // },
        // {
        //   value: "2",
        //   label: "客服类型",
        // },
        {
          value: "3",
          label: "客服工号",
        },
        {
          value: "4",
          label: "用户昵称",
        },
        {
          value: "5",
          label: "设备类型",
        },
        {
          value: "6",
          label: "IMEI",
        },
        {
          value: "7",
          label: "来电号码",
        },
      ],
      // 分页
      pageSize: 10,
      totalCount: 100,
      pageCount: 1,
      // 搜索
      input: "",
      //   表格
      tableData: [],
      bian0: "",
      aa: "",
      bb: "",
      cc: "",
      dd: "",
      ee: "",
      ff: "",
      gg: "",
      hh: "",
      beginDate: "", // 开始时间  例如：2020-09-16 14:25:23
      endDate: "", // 结束时间  例如：2020-10-16 14:25:23
      flag: false,
      chu: "",
      clicking: false,
      exID: null,
      excallType: "",
      exserviceType: "",
      exworkNumber: "",
      exname: "",
      exequipmentType: "",
      exequipmentId: "",
      phoneId: "",
    };
  },
  created() {
    this.initTime();
    this.yes();
    this.serviceTs();
  },
  mounted() {
    this.time();
    this.dateTime();
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    focus() {
      this.$nextTick(() => {
        document
          .getElementsByClassName("el-button--text")[0]
          .setAttribute("style", "display:none"); // 隐藏此刻按钮
      });
    },
    //客服类型的下拉框
    serviceTs() {
      api.smallLei({ dictCode: "servicetype" }).then((res) => {
        console.log("客服类型：", res.data);
        if (res.data.code === 200) {
          this.optionsKe = res.data.result;
        }
      });
    },
    yes() {
      api.getSelectProduct({ dictCode: "isno" }).then((res) => {
        //console.log("下拉框：", res.data);
        if (res.data.code == 200) {
          this.optionsDel = res.data.result;
        }
      });
    },
    exportHis() {
      if (this.bian0 == 0) {
        this.exID = this.inputOne;
      } else if (this.bian0 == 3) {
        this.exworkNumber = this.inputOne;
      } else if (this.bian0 == 4) {
        this.exname = this.inputOne;
      } else if (this.bian0 == 5) {
        this.exequipmentType = this.inputOne;
      } else if (this.bian0 == 6) {
        this.exequipmentId = this.inputOne;
      } else if (this.bian0 == 7) {
        this.phoneId = this.inputOne;
      }
      let that = this;
      that
        .$http({
          url: "/too/center/callCenterHistory/exportCallCenterHistoryList",
          params: {
            startDate: this.overallForm.time[0],
            endDate: this.overallForm.time[1],
            id: this.exID,
            callType: this.valueTong,
            serviceType: this.valueKe,
            workNumber: this.exworkNumber,
            name: this.exname,
            equipmentType: this.exequipmentType,
            equipmentId: this.exequipmentId,
            phone: this.phoneId,
          },
          method: "get",
          responseType: "blob",
          headers: {
            token: JSON.parse(localStorage.getItem("token")),
          },
        })
        .then((res) => {
          console.log("导出通话记录：", res.data);
          const blob = new Blob([res.data], {
            type: "application/vnd.ms-excel",
            crossOrigin: "Anonymous",
          });
          // const objectUrl = URL.createObjectURL(blob);
          // window.location.href = objectUrl;
          const a = document.createElement("a");
          let date = new Date();
          var year = date.getFullYear();
          var month =
            date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1;
          var day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
          var hours =
            date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
          var minutes =
            date.getMinutes() < 10
              ? "0" + date.getMinutes()
              : date.getMinutes();
          var seconds =
            date.getSeconds() < 10
              ? "0" + date.getSeconds()
              : date.getSeconds();
          // 拼接
          let ds =
            year +
            "-" +
            month +
            "-" +
            day +
            " " +
            hours +
            ":" +
            minutes +
            ":" +
            seconds;
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          a.download = "通话记录" + ds + ".xls";
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
    time() {
      var d = new Date();
      let youWant =
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDate() +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes() +
        ":" +
        d.getSeconds();
      this.value2 = youWant;
    },
    handleDialogClose() {
      console.log("关闭了");
      this.dialogVisibleYin = false;
      this.$refs.audioLing.pause();
    },
    //录音的播放
    handleSearch(index, row) {
      console.log("======", index, row);
      this.dialogVisibleYin = true;
      // controlslist
      if (row.qiniuUrl == null) {
        this.isShow = true;
        this.isShow1 = false;
      } else {
        this.isShow1 = true;
        this.isShow = false;
        this.yinImage = row.qiniuUrl;
      }
    },
    // 音频下载
    downloadBtn(index, row) {
      console.log("======", index, row);
      // 使用获取到的blob对象创建的url
      var filePath = row.qiniuUrl; // mp3的地址
      filePath = "https:" + filePath.split(":")[1];
      console.log(filePath, "filePath");
      fetch(filePath)
        .then((res) => res.blob())
        .then((blob) => {
          const a = document.createElement("a");
          document.body.appendChild(a);
          a.style.display = "none";
          // 使用获取到的blob对象创建的url
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          // 指定下载的文件名
          if (row.name == "") {
            a.download = row.startTime + ".mp3";
          } else {
            a.download = row.name + "-" + row.startTime + ".mp3";
          }
          a.click();
          document.body.removeChild(a);
          // 移除blob对象的url
          window.URL.revokeObjectURL(url);
        });
    },
    searchNei() {
      // 不删除这搜索时会带上上一次搜索的
      this.aa = "";
      this.bb = "";
      this.cc = "";
      this.dd = "";
      this.ee = "";
      this.ff = "";
      this.gg = "";
      this.hh = "";
      this.dateTime();
      this.clicking = true;
    },
    bian(val) {
      console.log("999:", val);
      this.bian0 = val;
      this.inputOne = "";
    },
    // 获取当前时间
    getFormatDate(date) {
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      var currentDate =
        date.getFullYear() +
        "-" +
        month +
        "-" +
        strDate +
        " " +
        date.getHours() +
        ":" +
        date.getMinutes() +
        ":" +
        date.getSeconds();
      return currentDate;
    },
    // 初始化时间
    initTime() {
      const endDate = this.getFormatDate(new Date()).substr(0, 11) + "23:59:59";
      // const beginDate =
      //   this.getFormatDate(new Date(new Date() - 3600 * 1000 * 24 * 31)).substr(
      //     0,
      //     11
      //   ) + "00:00:00";
      // this.beginDate = beginDate;
      // this.endTime = endDate;
      this.overallForm.time[1] = endDate;
      let aa = new Date();
      let b = aa.getFullYear();
      let c = aa.getMonth() + 1;
      // let d=aa.getDate();
      if ((b % 4 == 0 && b % 100 != 0) || b % 400 == 0) {
        console.log("闰年");
        if (
          c == 1 ||
          c == 3 ||
          c == 5 ||
          c == 7 ||
          c == 8 ||
          c == 10 ||
          c == 12
        ) {
          console.log("有31天");
          const beginDate =
            this.getFormatDate(
              new Date(new Date() - 3600 * 1000 * 24 * 31)
            ).substr(0, 11) + "00:00:00";
          // this.beginDate = beginDate;
          this.overallForm.time[0] = beginDate;
        } else if (c == 4 || c == 6 || c == 9 || c == 11) {
          const beginDate =
            this.getFormatDate(
              new Date(new Date() - 3600 * 1000 * 24 * 30)
            ).substr(0, 11) + "00:00:00";
          // this.beginDate = beginDate;
          this.overallForm.time[0] = beginDate;
        } else if (c == 2) {
          const beginDate =
            this.getFormatDate(
              new Date(new Date() - 3600 * 1000 * 24 * 29)
            ).substr(0, 11) + "00:00:00";
          // this.beginDate = beginDate;
          this.overallForm.time[0] = beginDate;
        }
      } else {
        console.log("平年");
        if (
          c == 1 ||
          c == 3 ||
          c == 5 ||
          c == 7 ||
          c == 8 ||
          c == 10 ||
          c == 12
        ) {
          const beginDate =
            this.getFormatDate(
              new Date(new Date() - 3600 * 1000 * 24 * 31)
            ).substr(0, 11) + "00:00:00";
          // this.beginDate = beginDate;
          this.overallForm.time[0] = beginDate;
        } else if (c == 4 || c == 6 || c == 9 || c == 11) {
          const beginDate =
            this.getFormatDate(
              new Date(new Date() - 3600 * 1000 * 24 * 30)
            ).substr(0, 11) + "00:00:00";
          // this.beginDate = beginDate;
          this.overallForm.time[0] = beginDate;
        } else if (c == 2) {
          const beginDate =
            this.getFormatDate(
              new Date(new Date() - 3600 * 1000 * 24 * 28)
            ).substr(0, 11) + "00:00:00";
          // this.beginDate = beginDate;
          this.overallForm.time[0] = beginDate;
        }
      }
    },
    //查询列表
    dateTime() {
      this.value1 = this.beginDate;
      // console.log("---:", this.bian1);
      if (this.bian0 == "0") {
        this.aa = this.inputOne;
      } else if (this.bian0 == "3") {
        this.dd = this.inputOne;
      } else if (this.bian0 == "4") {
        this.ee = this.inputOne;
      } else if (this.bian0 == "5") {
        this.ff = this.inputOne;
      } else if (this.bian0 == "6") {
        this.gg = this.inputOne;
      } else if (this.bian0 == "7") {
        this.hh = this.inputOne;
      }
      if (this.flag == false) {
        this.value1 = this.beginDate;
      } else {
        this.value1 = this.chu;
      }
      localStorage.setItem("callogValue", JSON.stringify(this.inputOne));
      apiShout
        .getcenHistory({
          pageSize: this.pageSize,
          pageCount: this.pageCount,
          startDate: this.overallForm.time[0],
          endDate: this.overallForm.time[1],
          id: this.aa,
          serviceType: this.valueKe,
          callType: this.valueTong,
          workNumber: this.dd,
          name: this.ee,
          equipmentType: this.ff,
          equipmentId: this.gg,
          phone: this.hh,
          delFlag: this.del,
        })
        .then((res) => {
          console.log("列表：", res.data);
          if (res.data.code == 200) {
            this.tableData = res.data.result.data;
            this.totalCount = res.data.result.page.totalCount;
            this.pageSize = res.data.result.page.pageSize;
            this.toTime = res.data.result.totalTime;
            for (let i = 0; i < this.tableData.length; i++) {
              // console.log("111:", this.tableData[i].bridgeDuration);
              let sh = this.tableData[i].bridgeDuration;
              let hour =
                Math.floor(sh / 3600) >= 10
                  ? Math.floor(sh / 3600)
                  : "0" + Math.floor(sh / 3600);
              sh -= 3600 * hour;
              let min =
                Math.floor(sh / 60) >= 10
                  ? Math.floor(sh / 60)
                  : "0" + Math.floor(sh / 60);
              sh -= 60 * min;
              let sec = sh >= 10 ? sh : "0" + sh;
              this.tableData[i].bridgeDuration = hour + ":" + min + ":" + sec;
            }
            // //清空搜索
            // this.inputOne = "";
            // this.valueGuan = "";
          }
          //   else if (
          //     res.data.msg == "token不正确" ||
          //     res.data.msg == "token不存在"
          //   ) {
          //     this.$router.push("/");
          //   }
        });
    },
    //时间的改变
    changeTime(val) {
      this.flag = true;
      var d = new Date(val);
      let youWant =
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDate() +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes() +
        ":" +
        d.getSeconds();
      this.chu = youWant;
      console.log("选择的开始的日期：", youWant);
    },
    //时间的改变
    changeTime1(value) {
      var d = new Date(value);
      let youWant =
        d.getFullYear() +
        "-" +
        (d.getMonth() + 1) +
        "-" +
        d.getDate() +
        " " +
        d.getHours() +
        ":" +
        d.getMinutes() +
        ":" +
        d.getSeconds();
      this.value2 = youWant;
      console.log("选择的结束的日期：", youWant);
    },
    //每页的条数分页
    handleSizeChange(size) {
      //console.log("size:", size);
      this.pageSize = size;
      this.dateTime();
    },
    //当前在第几页
    handleCurrentChange(pageCount) {
      //console.log("pageCount:", pageCount);
      if (this.clicking == true) {
        this.pageCount = pageCount;
        let oo = JSON.parse(localStorage.getItem("callogValue"));
        this.inputOne = oo;
        this.dateTime();
      } else {
        this.pageCount = pageCount;
        this.dateTime();
      }
    },
  },
};
</script>
<style lang="less" scoped>
.el-input__inner {
  height: 33px;
  line-height: 33px;
  padding: 0 10px;
}
/deep/.el-form-item__label {
  padding: 0px !important;
  text-align: left !important;
  color: #909399;
}
.userservice {
  .search {
    margin: 0 10px;
    margin-left: 25px;
    .span1 {
      display: block;
      font-size: 14px;
      width: auto;
      color: #606266;
      margin-right: 1%;
      font-weight: bold;
    }
    .el-select {
    }
    .el-input {
      width: 120px;
      margin-left: 10px;
    }
    .in1 {
      margin-right: 10px;
    }
    .in2 {
      margin-right: 60px;
    }
  }
  // table
  .tables {
    margin-left: 30px;
    margin-right: 40px;
    .el-table::before {
      height: 0;
    }
    .el-table {
      td,
      th.is-leaf {
        border: none !important;
      }
      tr th {
        font-size: 14px;
        color: #909399;
        text-align: center;
      }
      tr td {
        text-align: center;
        font-size: 14px;
        color: #606266;
      }
      .details {
        height: 30px;
        border-radius: 15px;
      }
    }
  }
  /deep/ .el-table .el-table__cell {
    padding: 12px 0;
    min-width: 0;
    box-sizing: border-box;
    text-overflow: ellipsis;
    vertical-align: middle;
    position: relative;
    text-align: center;
  }
  /deep/ .el-table td.el-table__cell,
  .el-table th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  /deep/ th.el-table__cell.is-leaf {
    border-bottom: none;
  }
  // 弹框
  .el-dialog {
    border-radius: 18px;
  }
  .el-dialog__header {
    // background: #eaeaea;
    border-radius: 18px 18px 0 0;
  }
  .el-dialog__close {
    color: transparent;
    background-image: url("../../assets/images/close.png");
    background-repeat: no-repeat;
    background-size: 14px;
  }
  .el-dialog__body {
    // background: #eaeaea;
    border-radius: 0 0 18px 18px;
    padding: 10px 20px 30px 20px;
  }
  // 总结
  .total {
    display: flex;
    justify-content: space-between;
    margin-left: 85px;
    margin-top: 20px;
    margin-right: 30px;
    .right {
      display: flex;
      align-items: center;
      margin-right: 30px;
      .el-pagination {
        padding: 0;
        .el-pager,
        .el-pager li {
          margin-top: 2px;
          color: #afafaf;
          min-width: 50px;
          font-size: 17px;
          font-weight: normal;
        }
        .el-pager li.active {
          color: #d78673;
        }
        .el-pager li:hover {
          color: #d78673;
        }
        img {
          display: inline-block;
        }
        .lefts {
          margin-right: 25px;
        }
        .rights {
          float: right;
          margin-left: 25px;
        }
      }
    }
    p {
      font-size: 15px;
      display: inline-block;
      margin-right: 80px;
      color: #606266;
    }
    .spanto {
      color: #92aca7;
      margin-left: 17px;
      margin-right: 12px;
    }
  }
  // 按钮
  .buttons {
    display: flex;
    justify-content: flex-end;
    margin-right: 30px;
    .el-button {
      width: 130px;
      height: 40px;
      line-height: 40px;
      border-radius: 25px;
      margin-top: 20px;
      margin-right: 20px;
      background: #d78673;
      color: #ffffff;
      font-size: 16px;
      padding: 0px;
    }
  }
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
// /deep/.el-picker-panel__footer .el-picker-panel__link-btn .el-button--text {
//   display: none !important;
// }
/deep/.el-button--text {
  display: none !important;
}
/deep/ .el-dialog__body {
  padding: 10px;
}
</style>